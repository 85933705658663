import React, { Component } from 'react';
import Routes from './Routes'
import Header from './Header'
import Footer from './Footer'

class App extends Component {
    render() {
        return (
            <div>
                <Header />
                    <div className="pb-5">
                        <Routes />
                    </div>
                <Footer />
            </div>    
        );
    }
}

export default App;           