import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <Container>
                    <Row>
                        <Col xs="6" className="text-left">
                            © 2019 Jackson Dwellings
                        </Col>
                        <Col xs="6" className="text-right">
                            <a className="black-link m-2" rel="noopener noreferrer" href="instagram.com/jacksondwellings/" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a> <a className="black-link m-2" rel="noopener noreferrer" href="facebook.com/jacksondwellings" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Footer;