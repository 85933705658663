import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Index from './Index'
import Services from './Services'
import AboutUs from './AboutUs'
import GalleryPage from './Gallery'
import Showroom from './Showroom'
import Contact from './Contact'


class Routes extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route exact path="/" component={Index}/>
                    <Route exact path="/about_us" component={AboutUs}/>
                    <Route exact path="/services" component={Services}/>
                    <Route exact path="/gallery" component={GalleryPage}/>
                    <Route exact path="/showroom" component={Showroom}/>
                    <Route exact path="/contact" component={Contact}/>
                    <Route path="*" component={Index}/>
                </Switch>
            </div>
        );
    }
}

export default Routes;