import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';

const IMAGES =
[
    { src: "/assets/gallery/gallery-1.png", width: 4, height: 3 },
    { src: "/assets/gallery/gallery-2.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-3.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-4.png", width: 4, height: 3 },
    { src: "/assets/gallery/gallery-5.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-6.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-7.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-8.png", width: 4, height: 3 },
    { src: "/assets/gallery/gallery-9.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-10.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-11.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-12.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-13.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-14.png", width: 4, height: 3 },
    { src: "/assets/gallery/gallery-15.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-16.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-17.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-18.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-19.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-20.png", width: 4, height: 3 },
    { src: "/assets/gallery/gallery-21.png", width: 1, height: 1 },
    { src: "/assets/gallery/gallery-22.png", width: 1, height: 1 },
    { src: "/assets/gallery/gallery-23.png", width: 4, height: 3 },
    { src: "/assets/gallery/gallery-24.png", width: 4, height: 3 },
    { src: "/assets/gallery/gallery-25.png", width: 4, height: 3 },
    { src: "/assets/gallery/gallery-26.png", width: 4, height: 3 },
    { src: "/assets/gallery/gallery-27.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-28.png", width: 1, height: 1 },
    { src: "/assets/gallery/gallery-29.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-30.png", width: 4, height: 3 },
    { src: "/assets/gallery/gallery-31.png", width: 4, height: 3 },
    { src: "/assets/gallery/gallery-32.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-33.png", width: 4, height: 3 },
    { src: "/assets/gallery/gallery-34.png", width: 1, height: 1 },
    { src: "/assets/gallery/gallery-35.png", width: 4, height: 3 },
    { src: "/assets/gallery/gallery-36.png", width: 1, height: 1 },
    { src: "/assets/gallery/gallery-37.png", width: 3, height: 4 },
    { src: "/assets/gallery/gallery-38.png", width: 4, height: 3 }
]

class GalleryPage extends Component {
    constructor() {
        super();
        this.state = { currentImage: 0 };
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
      }
      openLightbox(event, obj) {
        this.setState({
          currentImage: obj.index,
          lightboxIsOpen: true,
        });
      }
      closeLightbox() {
        this.setState({
          currentImage: 0,
          lightboxIsOpen: false,
        });
      }
      gotoPrevious() {
        this.setState({
          currentImage: this.state.currentImage - 1,
        });
      }
      gotoNext() {
        this.setState({
          currentImage: this.state.currentImage + 1,
        });
      }
    render() {
        return (
            <div>
                <div className="gallery"></div>
                <Container className="mt-5 pt-5 pb-5">
                    <Row>
                        <Col md="12" className="text-uppercase">
                            <h1 className="page-title">Gallery</h1>
                        </Col>
                    </Row>
                </Container>
                <Row className="gallery-content text-center pt-5">
                  <Col md="12">
                    <Container>
                      <Row>
                      <Col xl="4">
                        <div>
                          <video width="100%" height="100%" controls muted playsinline>
                            <source src="/assets/video/video1.mp4" type="video/mp4" />
                            Your browser does not support this video.
                          </video>
                        </div>
                      </Col>
                      <Col xl="4">
                        <div>
                          <video width="100%" height="100%" controls muted playsinline>
                            <source src="/assets/video/video2.mp4" type="video/mp4" />
                            Your browser does not support this video.
                          </video>
                        </div>
                      </Col>
                      <Col xl="4">
                        <div>
                          <video width="100%" height="100%" controls muted playsinline>
                            <source src="/assets/video/video3.mp4" type="video/mp4" />
                            Your browser does not support this video.
                          </video>
                        </div>
                      </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col md="12">
                      <Container>
                      <Gallery photos={IMAGES} onClick={this.openLightbox}/>
                      <Lightbox images={IMAGES}
                      onClose={this.closeLightbox}
                      onClickPrev={this.gotoPrevious}
                      onClickNext={this.gotoNext}
                      currentImage={this.state.currentImage}
                      isOpen={this.state.lightboxIsOpen}
                      />
                      </Container>
                  </Col>
                </Row>
            </div>
        );
    }
}

export default GalleryPage;