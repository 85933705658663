import React, { Component } from 'react';
import { Row, Col, Container, Card, CardHeader, CardImg, CardBody } from 'reactstrap';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';
import ShowroomHeader from './../Assets/showroom-header.gif'

const IMAGES =
[
    { src: "/assets/showroom/showroom-1.jpg", width: 960, height: 719 },
    { src: "/assets/showroom/showroom-2.jpg", width: 960, height: 719 },
    { src: "/assets/showroom/showroom-3.jpg", width: 4, height: 3 },
    { src: "/assets/showroom/showroom-4.jpg", width: 3, height: 2 },
    { src: "/assets/showroom/showroom-5.jpg", width: 800, height: 533 },
    { src: "/assets/showroom/showroom-6.jpg", width: 533 , height: 800 }
]

class Showroom extends Component {
    constructor() {
        super();
        this.state = { currentImage: 0 };
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
      }
      openLightbox(event, obj) {
        this.setState({
          currentImage: obj.index,
          lightboxIsOpen: true,
        });
      }
      closeLightbox() {
        this.setState({
          currentImage: 0,
          lightboxIsOpen: false,
        });
      }
      gotoPrevious() {
        this.setState({
          currentImage: this.state.currentImage - 1,
        });
      }
      gotoNext() {
        this.setState({
          currentImage: this.state.currentImage + 1,
        });
      }
    render() {
        return (
            <div>
              <div className="showroom"></div>
                <Container className="mt-5 pt-5 pb-5">
                  <Row>
                      <Col md="12" className="text-uppercase">
                          <h1 className="page-title">Showroom</h1>
                      </Col>
                  </Row>
                </Container>
                <Row className="gallery-content text-center pt-3">
                  <Col md="12">
                    <Container>
                      <Card>
                        <CardHeader className="text-center page-sub-title">GALLERIE – PORT MELBOURNE</CardHeader>
                        <CardImg top className="img-fluid" src={ShowroomHeader} alt="New Homes" />
                        <CardBody>
                          <div>
                              <p>All our clients are shown through our Gallerie in Port Melbourne.  This is an opportunity to personalise their home through the skilful selection of all of their fittings, fixtures and finishes. Appointments are conducted with the assistance of qualified interior designers who will share their expertise and industry knowledge to help them make each decision with confidence, bringing their dream home a step closer to reality.</p>
                              <div className="my-4"
                                style={{
                                  position: "relative",
                                  paddingBottom: "56.25%" /* 16:9 */,
                                  paddingTop: 25,
                                  height: 0
                                }}
                              >
                              <iframe
                              title="gallerie"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%"
                                }}
                                src={'https://www.youtube.com/embed/oKoE3MqCvO4'}
                                frameBorder="0"
                              />
                              </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Container>
                  </Col>
                  <Col md="12">
                    <Container>
                      <Gallery photos={IMAGES} onClick={this.openLightbox}/>
                      <Lightbox images={IMAGES}
                      onClose={this.closeLightbox}
                      onClickPrev={this.gotoPrevious}
                      onClickNext={this.gotoNext}
                      currentImage={this.state.currentImage}
                      isOpen={this.state.lightboxIsOpen}
                      />
                    </Container>
                  </Col>
                </Row>
            </div>
        );
    }
}

export default Showroom;