import React, { Component } from 'react';
import { Row, Col, Container, CardDeck, CardImg, CardBody, Card, CardHeader } from 'reactstrap';
import Services1 from '../Assets/services-1.png'
import Services2 from '../Assets/services-2.png'
import Services3 from '../Assets/services-3.png'
import Services4 from '../Assets/services-4.png'
import Services5 from '../Assets/services-5.png'
import Services6 from '../Assets/services-6.png'


class AboutUs extends Component {
    render() {
        return (
            <div>
                <div className="services"></div>
                <Container className="mt-5 pt-5 pb-5">
                    <Row>
                        <Col md="12" className="text-uppercase">
                            <h1 className="page-title">Services</h1>
                        </Col>
                    </Row>
                </Container>
                <Row className="services-content">
                    <Col md="12">
                        <Container>
                            <CardDeck className="mt-3">
                                <Card>
                                    <CardHeader className="text-center page-sub-title">CUSTOM NEW HOMES</CardHeader>
                                    <CardImg top className="img-fluid" src={Services1} alt="New Homes" />
                                    <CardBody>
                                            <div>
                                                <p>At Jackson Dwellings, we're here to bring your design and ideas to life.<br />We are experts in delivering high quality, personalised homes on time and on budget.</p>
                                                <p>We pride ourselves on establishing a transparent relationship with our clients based on honesty and trust. Your needs are our priority. Our communication is fast and authentic from the first moment you make contact with us.</p>
                                                <p>Walk through completed projects with Cameron himself and have face-to-face meetings with tradesman to discuss your preferences.</p>
                                            </div>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardHeader className="text-center page-sub-title">RENOVATIONS</CardHeader>
                                    <CardImg top className="img-fluid" src={Services2} alt="Renovations" />
                                    <CardImg top className="img-fluid" src={Services3} alt="Renovations" />
                                    <CardBody>
                                            <div>
                                                <p>Renovations are a big part of what we do here at Jackson Dwellings. From shed conversions and full home renovations to outdoor entertaining areas and extensions, we have a wealth of experience.</p>
                                                <p>We enjoy a variety of work, not only to keep things interesting but to provide our team of employees with a vast array of practical experience and knowledge.</p>
                                                <p>Talk to us about your renovation ideas today!</p>
                                            </div>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardHeader className="text-center page-sub-title">DESIGNS, PERMITS &amp; INSURANCE</CardHeader>
                                    <CardImg top className="img-fluid" src={Services4} alt="Designs, Permits &amp; Insurance" />
                                    <CardBody>
                                            <div>
                                                <p>We work closely with a reliable and efficient team of draftsman, engineers and surveyors.<br />Once you have your custom home designed and drafted, we do the rest!</p>
                                                <p>We proudly work with 'Intelligent Insurance Services' to get appropriate cover for every project within 48 hours of your deposit being paid.​</p>
                                                <p>Cameron Jackson is a proud member of Housing Industry Australia</p>
                                                <img src={Services5} className="img-fluid" alt="HIA Member" />
                                                <p>Registered Building Practitioner Number: DB-U 47313</p>
                                                <img src={Services6} className="img-fluid" alt="Registered Building Practitoner" />
                                            </div>
                                    </CardBody>
                                </Card>
      
    </CardDeck>
                        </Container>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AboutUs;