import React, { Component } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem, } from 'reactstrap';
import { Link } from 'react-router-dom'

class Header extends Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.state = {
          isOpen: false
        };
      }
      toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }
      closeMenu() {
        this.setState({
          isOpen: false
        });
      }
    render() {
        return (
            <Navbar expand="md">
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav navbar className="ml-auto mr-auto text-uppercase">
                        <Link to="/">
                            <NavItem onClick={this.closeMenu}>
                                Home
                            </NavItem>
                        </Link>
                        <Link to="/about_us">
                            <NavItem onClick={this.closeMenu}>
                                About Us
                            </NavItem>
                        </Link>
                        <Link to="/services">
                            <NavItem onClick={this.closeMenu}>
                                Services
                            </NavItem>
                        </Link>
                        <Link to="/gallery">
                            <NavItem onClick={this.closeMenu}>
                                Gallery
                            </NavItem>
                        </Link>
                        <Link to="/showroom">
                            <NavItem onClick={this.closeMenu}>
                                Showroom
                            </NavItem>
                        </Link>
                        <Link to="/contact">
                            <NavItem onClick={this.closeMenu}>
                                Contact
                            </NavItem>
                        </Link>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

export default Header;