import React, { Component } from 'react';
import { Row, Col, Container, Card, CardHeader, CardTitle, CardBody, CardText, CardFooter, Form, FormGroup, Input, Button } from 'reactstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'

const API_PATH = '/api/index.php';

class Contact extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: '',
            phone: '',
            email: '',
            message: '',
            mailSent: false,
            error: null
        }
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }
    handleFormSubmit = e => {
        e.preventDefault();
        axios({
          method: 'post',
          url: `${API_PATH}`,
          headers: { 'content-type': 'application/json' },
          data: this.state
        })
          .then(result => {
              if(result.data.sent){
                this.setState({
                    mailSent: result.data.sent,
                    name: '',
                    phone: '',
                    email: '',
                    message: ''
                  })
              } else {
                this.setState({
                    mailSent: result.data.sent,
                    error: result.data.message
                })
            }
          })
          .catch(error => this.setState({ error: error.message }));
      };
    render() {
        return (
            <div>
                <div className="contact"></div>
                <Container className="mt-5 pt-5 pb-5">
                    <Row>
                        <Col md="12" className="text-uppercase">
                            <h1 className="page-title">Contact</h1>
                        </Col>
                    </Row>
                </Container>
                <Row className="contact-content pt-5">
                    <Col md="12">
                        <Container>
                            <Row>
                            <Col md="6">
                                    <Card>
                                        <CardHeader className="text-uppercase page-sub-title">Email Us!</CardHeader>
                                        <CardBody>
                                                <Form>
                                                    <Row>
                                                        <Col md="6">
                                                            <FormGroup>
                                                                <Input type="text" name="name" id="name" placeholder="Name" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="6">
                                                            <FormGroup>
                                                                <Input type="number" name="phone" id="phone" placeholder="Phone" value={this.state.phone} onChange={e => this.setState({ phone: e.target.value })} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup>
                                                                <Input type="email" name="email" id="email" placeholder="Email" value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup>
                                                                <Input type="textarea" name="message" id="message" placeholder="Message" rows="4" value={this.state.message} onChange={e => this.setState({ message: e.target.value })} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup>
                                                                <Button className="btn-block send-button" name="submit" id="submit" onClick={e => this.handleFormSubmit(e)}>Send</Button>
                                                            </FormGroup>
                                                            <div>{this.state.mailSent && <div>Thank you for contacting us, We'll be in touch soon.</div> }</div>
                                                            <div>{(!this.state.mailSent && this.state.error !== null) && <div class="text-danger">{this.state.error}</div> }</div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6">
                                    <Card>
                                        <CardHeader className="text-uppercase page-sub-title">Get In Touch!</CardHeader>
                                        <CardBody>
                                        <CardTitle><strong>Jackson Dwellings</strong></CardTitle>
                                        <CardText>
                                            <strong>E. </strong>info@jacksondwellings.com.au<br />
                                            <strong>PH. </strong>0412 286 008<br />
                                            <strong>SHOWROOM. </strong>Factory 7A Newry Drive, New Gisborne
                                        </CardText>
                                        </CardBody>
                                        <CardFooter><a className="black-link m-2 fa-lg" rel="noopener noreferrer" href="https://www.instagram.com/jacksondwellings/" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a> <a className="black-link m-2 fa-lg" rel="noopener noreferrer" href="https://www.facebook.com/jacksondwellings" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a></CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <iframe title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.932289859648!2d144.60315871620034!3d-37.46232057981758!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6e3341348bdd5%3A0x43c618b7268bb716!2s7+Newry+Dr%2C+New+Gisborne+VIC+3438!5e0!3m2!1sen!2sau!4v1549340082308" width="100%" height="500" frameBorder="0" style={{border:0}} allowFullScreen></iframe>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Contact;