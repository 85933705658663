import React, { Component } from 'react';
import { Row, Col, Container, CardDeck, CardImg, CardBody, Card, CardHeader } from 'reactstrap';
import AboutUs1 from '../Assets/about-us-1.png'
import AboutUs2 from '../Assets/about-us-2.png'
import AboutUs3 from '../Assets/about-us-3.png'


class AboutUs extends Component {
    render() {
        return (
            <div>
                <div className="aboutus"></div>
                <Container className="mt-5 pt-5 pb-5">
                    <Row>
                        <Col md="12" className="text-uppercase">
                            <h1 className="page-title">About Us</h1>
                        </Col>
                    </Row>
                </Container>
                <Row className="aboutus-content">
                    <Col md="12">
                        <Container>
                            <CardDeck className="mt-3">
                                <Card>
                                    <CardHeader className="text-center page-sub-title">PROUD FAMILY BUSINESS</CardHeader>
                                    <CardImg top className="img-fluid" src={AboutUs1} alt="Card image cap" />
                                    <CardBody>
                                            <div>
                                                <p>Jackson Dwellings is a family business that was launched in 2010 by Cameron Jackson and his wife Emma.</p>
                                                <p>The husband and wife duo are dedicated renovators who share a passion for construction and design.</p>
                                                <p>Cameron Jackson is a Registered Building Practitioner and Carpenter by trade. With over a decade of experience, Cameron is a knowledgeable and committed Builder.</p>
                                            </div>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardHeader className="text-center page-sub-title">THE TEAM</CardHeader>
                                    <CardImg top className="img-fluid" src={AboutUs2} alt="Card image cap" />
                                    <CardBody>
                                            <div>
                                                <p>Jackson Dwellings currently employs two Supervisors, one qualified Carpenter and three apprentices.</p>
                                                <p>Nathan and Brent are excellent leaders and role models to their co-workers. They are hard-working and trust-worthy, making them fantastic assets to the Jackson Dwellings leadership team. </p>
                                                <p>Victor, Michael, Matt and Adam are brilliant employees who take pride in their work, possess flawless work ethic and never fail to bring a laugh and good attitude to the job-site.</p>
                                            </div>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardHeader className="text-center page-sub-title">GIVING BACK TO THE COMMUNITY</CardHeader>
                                    <CardImg top className="img-fluid" src={AboutUs3} alt="Card image cap" />
                                    <CardBody>
                                            <div>
                                                <p>Every Friday the Jackson Dwellings team proudly wears pink in support of Pregnancy and Infant Loss Awareness.</p>
                                                <p>Each month donations are made to charities such as the Bears of Hope Foundation and Sands Australia.</p>
                                            </div>
                                    </CardBody>
                                </Card>
                            </CardDeck>
                        </Container>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AboutUs;