import React, { Component } from 'react';
import JDLogo from '../Assets/jd-logo.png'

class Index extends Component {
    render() {
        return (
            <div>
                <div className="index"></div>
                <div className="index-text text-center ml-auto mr-auto pt-5 pb-5">
                    <h1 className="pt-3 index-welcome">Welcome To</h1>
                    <h1 className="index-jackson">Jackson Dwellings</h1>
                    <img src={JDLogo} alt="Jackson Dwellings" />
                </div>
            </div>
        );
    }
}

export default Index;